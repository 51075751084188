import React from 'react';
import { useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import IndexGallery from '../content/de/index-gallery';
import PageWrapper from '../components/page-wrapper/page-wrapper';
import NewsletterBox from '../components/newsletter-box/newsletter-box';
import HeroVideo from '../components/hero-video/hero-video';
import TextBanner from '../components/text-banner/text-banner';
import { HeroState } from '../components/hero/hero-context';
import PressBanner from '../components/press-banner/press-banner';
import Row from 'react-bootstrap/Row';
import ContentColumn from '../components/content-column/content-column';
import Container from 'react-bootstrap/Container';

const IndexPage = () => {
  const { t } = useTranslation('home');
  return (
    <PageWrapper padding>
      <SEO title={t('header.title')} />
      <HeroState clear />
      <HeroVideo />
      <TextBanner />
      <IndexGallery />
      <NewsletterBox />
      <Container>
        <Row>
          <ContentColumn md={{ offset: 1, span: 10 }} spacingY className="pb-0">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/aHz2rU_xK3g?si=n6XcJEIF5KJx_4FZ"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              style={{
                width: '100%',
                height: 'auto',
                aspectRatio: '16/9',
              }}
            ></iframe>
          </ContentColumn>
        </Row>
      </Container>
      <PressBanner />
    </PageWrapper>
  );
};

export default IndexPage;
